import React, { useEffect, useState } from "react";
import { BLACK_PDF, BLUE_THEME, GRAY_BG_UX, GRAY_BORDER_TABLE, GRAY_PDF, GRAY_STRONG_UX, PAPER_PADDING_THEME, paperStyle, WHITE_ESCRITA_THEME, YELLOW_BG_UX } from "../../shared/utils";
import { Grid, Paper, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, TextField, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import YellowButton from "../../components/Buttons/YellowButton";
import api from "../../axios";
import SelectStore from "../../components/SelectStore/SelectStore";
import {showSnackMessage} from "../../actions/SnackActions";

const IncentivoVoxel = () => {
	const disptch = useDispatch();

	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const storeId = useSelector(state => state.StoreReducer.storeId);

	const [step, setStep] = useState(0);
	const [indicacoesConcluidas, setIndicacoesConcluidas] = useState([]);
	const [indicacoesEmAndamento, setIndicacoesEmAndamento] = useState([]);

	const [nome, setNome] = useState("");
	const [contato, setContato] = useState("");
	const [restaurante, setRestaurante] = useState("");

	useEffect(() => {
		getIndicacoes();
	}, [storeId]);

	const getIndicacoes = () => {
		const dataRequest = {
			loja_id: storeId
		};

		api.GetIndicacoes(dataRequest).then((response) => {
			setIndicacoesConcluidas(response.data.concluidas);
			setIndicacoesEmAndamento(response.data.abertas);
			setStep(response.data.progresso);
		}).catch(() => {
			disptch(showSnackMessage({message: "Erro ao buscar indicações", type: "error"}));
		});
	};

	const createIndicacao = () => {
		const dataRequest = {
			loja_id: storeId,
			nome: nome,
			contato: contato,
			restaurante: restaurante
		};

		api.CreateIndicacao(dataRequest).then(() => {
			getIndicacoes();
			clearInputs();
			disptch(showSnackMessage({message: "Indicação enviada com sucesso", type: "success"}));
		}).catch(() => {
			disptch(showSnackMessage({message: "Erro ao enviar indicação", type: "error"}));
		});
	};

	const clearInputs = () => {
		setNome("");
		setContato("");
		setRestaurante("");
	};

	return (
		<div className="main">
			{
				storeId == 0 ? (
					<SelectStore/>
				) : (
					<Paper sx={{...paperStyle, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : GRAY_BG_UX, backgroundImage: temaEscuro && "none", gap: 2}}>
						<h1 style={{color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX, textAlign: "center", fontSize: "24px"}}>Indique a Voxel e ganhe até 1 ano de mensalidade grátis!</h1>
						<Divider/>
						<Grid container>
						<Grid item xs={12} sx={{display: "flex", gap: "2rem", justifyContent: "center", alignItems: "center", mb: "2rem", mt: "1rem"}}>
								<Grid container xs={2} sx={{display: "flex", flexDirection: "column"}}>
									<InputLabel sx={{padding: ".2rem .8rem", fontWeight: "bold", color: temaEscuro ? BLUE_THEME : GRAY_STRONG_UX, backgroundColor: YELLOW_BG_UX, borderRadius: "5px 5px 0px 0px"}}>
										Nome
									</InputLabel>
									<TextField
										size="small"
										sx={{
											backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
											border: "1px solid", borderRadius: "0px 0px 5px 5px",
											borderColor: YELLOW_BG_UX,
										}}
										onChange={(e) => setNome(e.target.value)}
									/>
								</Grid>
								<Grid container xs={2} sx={{display: "flex", flexDirection: "column"}}>
									<InputLabel sx={{padding: ".2rem .8rem", fontWeight: "bold", color: temaEscuro ? BLUE_THEME : GRAY_STRONG_UX, backgroundColor: YELLOW_BG_UX, borderRadius: "5px 5px 0px 0px"}}>
										Contato
									</InputLabel>
									<TextField
										size="small"
										sx={{
											backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
											border: "1px solid", borderRadius: "0px 0px 5px 5px",
											borderColor: YELLOW_BG_UX,
										}}
										onChange={(e) => setContato(e.target.value)}
									/>
								</Grid>
								<Grid container xs={2} sx={{display: "flex", flexDirection: "column"}}>
									<InputLabel sx={{padding: ".2rem .8rem", fontWeight: "bold", color: temaEscuro ? BLUE_THEME : GRAY_STRONG_UX, backgroundColor: YELLOW_BG_UX, borderRadius: "5px 5px 0px 0px"}}>
										Restaurante
									</InputLabel>
									<TextField
										size="small"
										sx={{
											backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
											border: "1px solid", borderRadius: "0px 0px 5px 5px",
											borderColor: YELLOW_BG_UX,
										}}
										onChange={(e) => setRestaurante(e.target.value)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} sx={{display: "flex", justifyContent: "center", mb: "1.5rem"}}>
								<YellowButton
									title="Indicar"
									onClick={() => createIndicacao()}
								/>
							</Grid>
							<Grid item xs={12}>
								<p style={{textAlign: "center", fontSize: "16px"}}>
									Indique por qualquer canal e ganhe!
								</p>
								<Divider/>
							</Grid>
							<Grid item xs={12} sx={{mt: "2rem", display: "flex", flexDirection: "column", gap: "1.5rem"}}>
								<span style={{textAlign: "center", fontWeight: "bold", fontSize: "20px"}}>Jornada Inicial</span>
								<Stepper
									alternativeLabel
									activeStep={step}
									connector={
										<StepConnector
											sx={{
												[`&.${stepConnectorClasses.alternativeLabel}`]: {
													left: "calc(-50%)",
													right: "calc(50%)",
												},
												[`&.${stepConnectorClasses.active}`]: {
													[`& .${stepConnectorClasses.line}`]: {
													backgroundColor: YELLOW_BG_UX
													},
												},
												[`&.${stepConnectorClasses.completed}`]: {
													[`& .${stepConnectorClasses.line}`]: {
													backgroundColor: YELLOW_BG_UX
													},
												},
												[`& .${stepConnectorClasses.line}`]: {
													height: 4,
													border: 0,
													backgroundColor: GRAY_BORDER_TABLE,
												},
											}}
										/>
									}
								>
									<Step
										sx={{
											"& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
												color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_PDF,
												fontWeight: "500",
											},
											"& .MuiStepLabel-root .Mui-completed": {
												color: YELLOW_BG_UX,
											},
											"& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
												color: temaEscuro ? GRAY_BG_UX : BLACK_PDF,
												fontWeight: "bold",
											},
											"& .MuiStepLabel-root .Mui-active": {
												color: YELLOW_BG_UX,
											},
											"& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":{
												color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_PDF,
											},
											"& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
												fill: BLACK_PDF,
											},
											"& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-text": {
												fill: BLACK_PDF,
											},
											"& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-root": {
												fill: GRAY_BORDER_TABLE,
											},
											"& .MuiSvgIcon-root": {
												width: "30px",
												height: "30px",
												fontWeight: "bold",
												zIndex: 1,
												backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
												borderRadius: "50%",
											}
										}}
									>
										<StepLabel >
											<span style={{fontSize: "14px"}}>
												40% de desconto na mensalidade
											</span>
										</StepLabel>
									</Step>
									<Step
										sx={{
											"& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
												color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_PDF,
												fontWeight: "500",
											},
											"& .MuiStepLabel-root .Mui-completed": {
												color: YELLOW_BG_UX,
											},
											"& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
												color: temaEscuro ? GRAY_BG_UX : BLACK_PDF,
												fontWeight: "bold",
											},
											"& .MuiStepLabel-root .Mui-active": {
												color: YELLOW_BG_UX,
											},
											"& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":{
												color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_PDF,
											},
											"& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
												fill: BLACK_PDF,
											},
											"& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-text": {
												fill: BLACK_PDF,
											},
											"& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-root": {
												fill: GRAY_BORDER_TABLE,
											},
											"& .MuiSvgIcon-root": {
												width: "30px",
												height: "30px",
												fontWeight: "bold",
												zIndex: 1,
												backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
												borderRadius: "50%",
											}
										}}
									>
										<StepLabel>
											<span style={{fontSize: "14px"}}>
												1 mês grátis + Estudo Personalizado
											</span>
										</StepLabel>
									</Step>
									<Step
										sx={{
											"& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
												color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_PDF,
												fontWeight: "500",
											},
											"& .MuiStepLabel-root .Mui-completed": {
												color: YELLOW_BG_UX,
											},
											"& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
												color: temaEscuro ? GRAY_BG_UX : BLACK_PDF,
												fontWeight: "bold",
											},
											"& .MuiStepLabel-root .Mui-active": {
												color: YELLOW_BG_UX,
											},
											"& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":{
												color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_PDF,
											},
											"& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
												fill: BLACK_PDF,
											},
											"& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-text": {
												fill: BLACK_PDF,
											},
											"& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-root": {
												fill: GRAY_BORDER_TABLE,
											},
											"& .MuiSvgIcon-root": {
												width: "30px",
												height: "30px",
												fontWeight: "bold",
												zIndex: 1,
												backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
												borderRadius: "50%",
											}
										}}
									>
										<StepLabel sx={{fontWeight: step == 3 && "bold"}}>
											<span style={{fontSize: "14px"}}>
												2 meses grátis + Consultoria Contábil
											</span>
										</StepLabel>
									</Step>
									<Step
										sx={{
											"& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
												color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_PDF,
												fontWeight: "500",
											},
											"& .MuiStepLabel-root .Mui-completed": {
												color: YELLOW_BG_UX,
											},
											"& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
												color: temaEscuro ? GRAY_BG_UX : BLACK_PDF,
												fontWeight: "bold",
											},
											"& .MuiStepLabel-root .Mui-active": {
												color: YELLOW_BG_UX,
											},
											"& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":{
												color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_PDF,
											},
											"& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
												fill: BLACK_PDF,
											},
											"& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-text": {
												fill: BLACK_PDF,
											},
											"& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-root": {
												fill: GRAY_BORDER_TABLE,
											},
											"& .MuiSvgIcon-root": {
												width: "30px",
												height: "30px",
												fontWeight: "bold",
												zIndex: 1,
												backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
												borderRadius: "50%",
											}
										}}
									>
										<StepLabel>
											<span style={{fontSize: "14px"}}>
												3,5 meses grátis + Acesso a Evento Exclusivo
											</span>
										</StepLabel>
									</Step>
									<Step
										completed={step == 4}
										sx={{
											"& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
												color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_PDF,
												fontWeight: "500",
											},
											"& .MuiStepLabel-root .Mui-completed": {
												color: YELLOW_BG_UX,
											},
											"& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
												color: temaEscuro ? GRAY_BG_UX : BLACK_PDF,
											},
											"& .MuiStepLabel-root .Mui-active": {
												color: YELLOW_BG_UX,
											},
											"& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":{
												color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_PDF,
											},
											"& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
												fill: BLACK_PDF,
											},
											"& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-text": {
												fill: BLACK_PDF,
											},
											"& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-root": {
												fill: GRAY_BORDER_TABLE,
											},
											"& .MuiSvgIcon-root": {
												width: "30px",
												height: "30px",
												fontWeight: "bold",
												zIndex: 1,
												backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
												borderRadius: "50%",
											}
										}}
									>
										<StepLabel>
											<span style={{fontSize: "14px"}}>
												5 meses de desconto + Reunião Estratégica Premium
											</span>
										</StepLabel>
									</Step>
								</Stepper>
								<p style={{fontWeight: "bold", textAlign: "center"}}>
									Acumule e ganhe mais!
								</p>
								<Divider
									sx={{m: "2rem 0"}}
								/>
							</Grid>
						</Grid>
						<Grid container sx={{display: "flex", justifyContent: "center"}} spacing={2}>
							<Grid item xs={5}>
								<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell sx={{textAlign: "center", backgroundColor: temaEscuro ? BLUE_THEME : GRAY_PDF, fontWeight: "bold", fontSize: "16px"}}>
													Indicações que já contam com a Voxel
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{
												indicacoesConcluidas.map((indicacao, index) => {
													return (
														<TableRow key={index}>
															<TableCell key={index} sx={{textAlign: "center", fontSize: "14px"}}>
																{indicacao}
															</TableCell>
														</TableRow>
													);
												})
											}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
							<Grid item xs={5} sx={{textAlign: "center"}}>
								<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell sx={{textAlign: "center", backgroundColor: temaEscuro ? BLUE_THEME : GRAY_PDF, fontWeight: "bold", fontSize: "16px"}}>
													Indicações que não fecharam
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{
												indicacoesEmAndamento.map((indicacao, index) => {
													return (
														<TableRow key={index}>
															<TableCell key={index} sx={{textAlign: "center", fontSize: "14px"}}>
																{indicacao}
															</TableCell>
														</TableRow>
													);
												})
											}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						</Grid>
					</Paper>
				)
			}
		</div>
	);
};

export default IncentivoVoxel;