import React, { useState, useEffect, useCallback } from "react";
import { DataGridPremium, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, useGridApiRef} from "@mui/x-data-grid-premium";
import { ptBR } from "@mui/x-data-grid-premium/locales";
import {GRAY_STRONG_UX, WHITE_THEME_BLACK, BLUE_THEME, INFO_THEME, WHITE_ESCRITA_THEME, LINE_TABLE, PERFIL_THEME, BORDER_TABLE, GRAY_BORDER_TABLE} from "../../shared/utils";
import DefaultFooter from "../Footers/DefaultFooter";
import { Grid } from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {changeScrollPositionDataGrid} from "../../actions/StoreActions";

function CustomToolbar() {
    return (
        <div style={{ borderRadius: "8px 8px 0 0", display: "flex", justifyContent: "flex-end", padding: "10px 10px 0 10px"}} data-testid="datagrid-export">
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        </div>
    );
}

const DataGrid = (props) => {
    const { 
        columns,
        data,
        row,
        initialColumns,
        customFooter,
        customModal,
        checked,
        customRowSelectionModelChange,
        customHandleCellClick,
        customHandleCellDoubleClick,
        customCellModesModel,
        CustomSelectionModel,
        setCellModesModel,
        openModal = false,
        hasScrollPrevious = false,
        getDetailPanelHeight,
        getDetailPanelContent
    } = props;

    const apiRef = useGridApiRef();
    const dispatch = useDispatch();

    const [selectedCells, setSelectedCells] = useState([]);
    const [operation, setOperation] = useState("Soma");
    const [sum, setSum] = useState(0);
    const options = ["Soma", "Média", "Contagem"];
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const scrollPrevious = useSelector(state => state.StoreReducer.scrollPositionDataGrid);
    const openAccordionSelected = useSelector(state => state.StoreReducer.openAccordionSelected);

    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([]);
    
    useEffect(() => {
        const scrollPosition = apiRef.current.getScrollPosition();
        dispatch(changeScrollPositionDataGrid(scrollPosition));
    }, [openModal]);

    // UseEffect para scrollar a tabela para a posição anterior, quando ela for renderizada
    useEffect(() => {
        if (hasScrollPrevious && scrollPrevious !== undefined) {
            setTimeout(() => {
                apiRef.current.scroll(scrollPrevious);
            }, 1);
        }
    }, []);

    useEffect(() => {
        try {
            if (operation in Operations) {
                const result = Operations[operation](selectedCells);
                setSum(result.toFixed(2));
            }
        } catch {
            // pass
        }
    }, [selectedCells, operation]);

    useEffect(() => {
        if (detailPanelExpandedRowIds.length > 0) {
            apiRef.current.toggleDetailPanel(detailPanelExpandedRowIds[0]);
        }
    }, [openAccordionSelected]);

    const calculateSum = (cells) => {
        return cells.reduce((acc, cell) => acc + cell, 0);
    };

    const calculateAverage = (cells) => {
        if (cells.length === 0) {
            return 0;
        }
        const sum = calculateSum(cells);
        return sum / cells.length;
    };

    const Operations = {
        "Soma": calculateSum,
        "Média": calculateAverage,
        "Contagem": (cells) => cells.length,
    };

    const clearSum = () => {
        setSelectedCells([]);
        const initialSum = 0;
        const parsedSum = parseFloat(sum) || initialSum;
        setSum(parsedSum.toFixed(2));
    };

    const handleCellSelectionModelChange = () => {
        const selectedCellsList = apiRef.current.getSelectedCellsAsArray();
        let values = [];
        let cellValues = 0;
        let cellRequest = "";
        selectedCellsList.forEach(cells => {
            const cellId = cells.id;
            const cellField = cells.field;
            if (["recebido_portal", "recebido_cc", "recebido_conferencia"].includes(cellField)) {
                cellRequest = apiRef.current.getCellValue(cellId, cellField);
                navigator.clipboard.writeText((cellRequest));
                let cellRequestReplace = cellRequest.replace(".", "").replace(",", ".");
                cellRequest = parseFloat(cellRequestReplace);
            }
            else {
                cellRequest =  apiRef.current.getCellValue(cellId, cellField);
            }

            try {
                if (cellRequest.includes("/")) {
                    return values;
                }
            } catch {
                cellValues = parseFloat(cellRequest);
                if (!isNaN(cellValues)) {
                    values.push(cellValues);
                } else {
                    return values;
                }
            }
        });
        setSelectedCells(values);
    };

    const handleCellModesModelChange = useCallback((newModel) => {
        setCellModesModel(newModel);
    }, []);
    
    const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
        setDetailPanelExpandedRowIds(
            newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
        );
    }, []);
    
    return (
        <React.Fragment>
            {customModal && (
                customModal
            )}
            <DataGridPremium
                apiRef={apiRef}
                data-testid="datagrid-component"
                sx={{
                    "& .MuiDataGrid-cell.MuiDataGrid-cell--editing" : {
                        backgroundColor: "transparent"
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontSize: "16px",
                        fontWeight: "500",
                        color: temaEscuro ? LINE_TABLE : GRAY_STRONG_UX
                    },
                    "& ::-webkit-scrollbar-thumb": {
                        backgroundColor: temaEscuro ? PERFIL_THEME : WHITE_ESCRITA_THEME
                    },
                    "& .MuiDataGrid-row--editing .MuiDataGrid-cell": {
                        backgroundColor: "transparent"
                    },
                    fontSize: "14px", fontWeight: 400, borderRadius: 2, borderColor: temaEscuro && INFO_THEME
                }}
                style={{"--DataGrid-containerBackground": temaEscuro && BLUE_THEME, "--DataGrid-pinnedBackground": temaEscuro && BLUE_THEME, backgroundColor: temaEscuro && BLUE_THEME, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_STRONG_UX}}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                checkboxSelection={checked}
                onRowSelectionModelChange={customRowSelectionModelChange}
                rows={data}
                getRowId={row}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                onCellClick={customHandleCellClick}
                onCellDoubleClick={customHandleCellDoubleClick}
                cellModesModel={customCellModesModel}
                onCellModesModelChange={handleCellModesModelChange}
                selectionModel={CustomSelectionModel}
                cellSelection
                onCellSelectionModelChange={handleCellSelectionModelChange}
                initialState={initialColumns}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                slots={{
                    toolbar: CustomToolbar,
                    footer: () => (
                        <Grid container sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: 2, borderTop: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${GRAY_BORDER_TABLE}`}}>
                            {customFooter ? (
                                customFooter
                            ) : (
                                <DefaultFooter 
                                    clearSum={clearSum}
                                    operation={operation}
                                    options={options}
                                    sum={sum}
                                    setOperation={setOperation}
                                    dataLength={data.length}
                                />
                            )}
                        </Grid>
                    ),
                }}
            />
        </React.Fragment>
    );
};

export default React.memo(DataGrid);
